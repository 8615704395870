<template>
  <div
    ref="layoutWidget"
    :class="{
      'layout-widget': true,
      'is-loading': isLoading
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import {
  useServerSeoMeta,
  onMounted,
  onBeforeUpdate,
  onBeforeUnmount,
  ref,
  useConfigEnv,
  useHead
} from '#imports'
import { useElementBounding } from '@vueuse/core'
import useTracking from '@ha/components-v3/src/composables/useTracking'
import { functions } from '@ha/helpers'

const { tracking } = useTracking()
const isLoading = ref(true)
const config = useConfigEnv()

useServerSeoMeta({
  title: 'Widget Explore | HelloAsso',
  robots: 'noindex'
})

useHead({
  link: [
    {
      rel: 'canonical',
      href: `${config.NUXT_ENV_BASE_URL}/e/recherche`
    }
  ]
})

onMounted(() => {
  iframePostMessage()
  window.addEventListener('resize', iframePostMessage)
  window.addEventListener('load', iframePostMessage)
  tracking?.page({
    rubrik: 'Explore',
    category: 'Widget',
    title: 'Explore',
    version: config.PACKAGE_VERSION
  })
})

onBeforeUpdate(() => {
  iframePostMessage()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', iframePostMessage)
  window.removeEventListener('load', iframePostMessage)
})

const layoutWidget = ref(null)
const iframe = useElementBounding(layoutWidget)

const iframePostMessage = functions.debounce(() => {
  const message = { height: iframe.height.value }
  // window.top refers to parent window
  window.top.postMessage(message, '*')
  isLoading.value = false
})
</script>

<style lang="scss">
// TODO - remove ? this override body of host page, issue observed on local dev env!
body .widget {
  background: transparent;
}

.layout-widget {
  overflow: hidden;

  .carousel--ShowAllResults {
    display: none;
  }
}
</style>
